<template>
    <ckeditor :editor="editor"
              :value="editorValue"
              @input="updateValue"
              :config="editorConfig"
              class="custom-balloon-editor"
              @ready="ready"/>
</template>

<script>
    import CKEditor      from '@ckeditor/ckeditor5-vue2';
    import BalloonEditor from '@ckeditor/ckeditor5-build-balloon/packages/ckeditor5-build-balloon';
    import {mapState}    from 'vuex';

    export default {
        name: "BalloonEditor",
        components: {ckeditor: CKEditor.component},
        props: {
            value: {},
            imageUploadUrl: {
                type: String,
                default: null
            }
        },
        data: () => ({
            editorValue: null,
            editor: BalloonEditor,
            editorInstance: null
        }),
        computed: {
            ...mapState({user: 'user'}),
            editorConfig() {
                let config = {
                    simpleUpload: {
                        // The URL that the images are uploaded to.
                        uploadUrl: this.imageUploadUrl || '/404', // If there isn't an imageUploadUrl there won't be an image upload button anyway

                        // Headers sent along with the XMLHttpRequest to the upload server.
                        headers: {Authorization: 'Bearer ' + this.user.token}
                    }
                };

                if (!this.imageUploadUrl) { // If there isn't an imageUploadUrl, disable the image upload button
                    config.toolbar = {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'link',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'undo',
                            'redo'
                        ]
                    }
                }

                return config;
            }
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
            ready(editor) {
                this.editorValue    = this.value;
                this.editorInstance = editor;
            }
        }
    }
</script>

<style>
    .custom-balloon-editor.ck {
        text-align: left;
        border: 1px solid #cad1d7 !important;
    }

    .custom-balloon-editor.ck.ck-rounded-corners {
        border-radius: .375rem !important;
    }

    .custom-balloon-editor {
        min-height: 145px;
    }

    /* CKEditor toolbar over modals */
    div.ck.ck-balloon-panel {
        z-index: 1051 !important; /* Modal = 1050 */
    }
</style>